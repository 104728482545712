import "../scss/main.scss";
import "keen-slider/keen-slider.min.css";
import "swiper/css";
import "minibarjs/dist/minibar.min.css";
import "./components/lazyLoad";
import "./libs/fancy";
import "./components/advantagesSlider";
import "./alpine";
import "./components/header";
import "./components/scaleImg";
import "./components/productsSlider";
import "./components/projectsSlider";
import "./components/newsSlider";
import "./components/callbackForm";
import "./components/map";
import "./components/mainSlider";
import "./components/typeSlider";
import "./components/colorSlider";
import "./components/specificationMore";
import "./components/applicationationSlider";
import "./components/surfaceSlider";
import "./components/equipmentSlider";
import "./components/orderSlider";
import "./components/loadMore";
import "./components/projectPage";
import "./components/postSlider";
import "./components/contact";
import "./components/modal";
import "./components/calculate";
import "./components/accordeon";
import "./components/sidebar";
import "./components/bannerSlider";
import "./components/renderSlider";
import "./components/applicationType2Slider";
import "./components/featuresSlider";
import "./components/specificationsTabs";
import "./components/advantagesType2Slider";
import "./components/textureSlider";
import "./components/videoSlider";
import "./components/circlesSlider";
import "./components/squareLinkBlocksSlider";
import "./components/advantagesType3Slider";
import "./components/circlediagramm";
import "./components/storyline.js";
import "./components/do_posle_slider.js";
import "./components/gallery";
import "./components/preloader";
import "./components/PhotoVideoBanner";
import "./components/beforeAfterSlider";
