import Swiper from "swiper"
import { Navigation, Pagination, Autoplay } from "swiper/modules"

function projectsSlider() {
	const sliderContainer = document.querySelector("#before_to_after")
	if (!sliderContainer) return

	const slider = sliderContainer.querySelector(".section-before_to_after__swiper-main")
	if (slider) {
		const swiper = new Swiper(slider, {
			speed: 1000,
			loop:true,
			autoplay: {
				delay: 3000,
				disableOnInteraction: true,
				pauseOnMouseEnter: true,
			},
			modules: [Navigation, Autoplay],
			navigation: {
				nextEl: sliderContainer.querySelector(".slider-arrow--right"),
				prevEl: sliderContainer.querySelector(".slider-arrow--left"),
			},
			allowTouchMove: false,
			breakpoints: {
				// 1279: {
				// 	initialSlide: slider.querySelector('.swiper-wrapper').childElementCount - 1,
				// }
			},
		})

		const infoContainer = sliderContainer.querySelector("[data-info-container]")
		const name = infoContainer.querySelector(".section-before_to_after__info-title")
		const text = infoContainer.querySelector(".section-before_to_after__info-desc")

		const changeInfo = (data) => {
			name.textContent = data.name
			if (text) {
				text.innerHTML = data.text
			}
		}

		swiper.on("slideChange", function () {
			const currentIndex = this.activeIndex
			const currentSlide = this.slides[currentIndex]

			changeInfo(currentSlide.dataset)
		})
	}
}

projectsSlider()

function beforeAfterSlider() {
    const container = document.querySelector('#before_to_after');
    const sliders = container?.querySelectorAll('.section-applicationation__item');
    
    sliders?.forEach(slider => {
        const before = slider.querySelector('.section-applicationation__item-before');
        const change = slider.querySelector('.section-applicationation__item-change');
        
        let isActive = false;
        
        const activate = () => isActive = true
        const deactivate = () => isActive = false

        change.addEventListener('mousedown', (event) => {
            console.log(event)
            activate()
        });
        document.addEventListener('mouseup', deactivate);
        document.addEventListener('mouseleave', deactivate);
        
        const beforeAfterSlider = (x) => {
            const shift = Math.max(0, Math.min(x, slider.offsetWidth));
            before.style.width = `${shift}px`;
            change.style.left = `${shift}px`;
        };
        
        const pauseEvents = (e) => {
            e.stopPropagation();
            e.preventDefault();
            return false;
        };
        
        document.addEventListener('mousemove', (e) => {
            if (!isActive)
                return
        
            let x = e.pageX;
            x -= slider.getBoundingClientRect().left;
            beforeAfterSlider(x);
            pauseEvents(e);
        });
        
        change.addEventListener('touchstart', activate);
        document.addEventListener('touchend', deactivate);
        document.addEventListener('touchcancel', deactivate);
        
        document.addEventListener('touchmove', (e) => {
            if (!isActive) {
                return;
            }
        
            let x;
            for (let i = 0; i < e.changedTouches.length; i++) {
                x = e.changedTouches[i].pageX; 
            }
            
            x -= slider.getBoundingClientRect().left;
            
            beforeAfterSlider(x);
            pauseEvents(e);
        });
    });
}

beforeAfterSlider()